import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Settings
const requireValidDamn = false; // Require a valid DAMN number to add an item

// Create a styled container
const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; // Optional: if you want to stack items vertically
`;

/* The child div that wraps the two inner divs and is limited to 800px */
const WrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
  justify-content: center; /* Added to distribute space when both children are on the same line */
  
  @media (max-width: 864px) {
    justify-content: center; /* Center the children when they wrap to a new line */
  }
`;

const FirstChild = styled.div`
  flex: 0 1 auto;
`;

const SecondChild = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 300px;

  @media (max-width: 864px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

const Bold = styled.span`
	font-weight: bold;
`;

const StyledTableRow = styled.tr`
  &:hover {
    background-color: lightgrey; // Change background color on hover
  }

  a {
    visibility: hidden;
  }

  &:hover a {
    visibility: visible; // Show link on hover
  }
`;

const damnTable = [
	[0, 3, 1, 7, 5, 9, 8, 6, 4, 2],
	[7, 0, 9, 2, 1, 5, 4, 8, 6, 3],
	[4, 2, 0, 6, 8, 7, 1, 3, 5, 9],
	[1, 7, 5, 0, 9, 8, 3, 4, 2, 6],
	[6, 1, 2, 3, 0, 4, 5, 9, 7, 8],
	[3, 6, 7, 4, 2, 0, 9, 5, 8, 1],
	[5, 8, 6, 9, 7, 2, 0, 1, 3, 4],
	[8, 9, 4, 5, 3, 6, 2, 0, 1, 7],
	[9, 4, 3, 8, 6, 1, 7, 2, 0, 5],
	[2, 5, 8, 1, 4, 3, 6, 7, 9, 0],
];

const damnLookup = (p, c) => damnTable[p][parseInt(c, 10)];
const damm = input => [...input].reduce(damnLookup, 0) === 0;

const isValidDamn = (value) => {
	if (value.length !== 6 || isNaN(value)) return false;
	return damm(value);
};

const TechAudit = () => {
	const [auditStatus, setAuditStatus] = useState('');
	const [assetId, setAssetId] = useState('');
	const [description, setDescription] = useState('');
	const [items, setItems] = useState([]);

	// Fetch audit status from the API
	useEffect(() => {
		const fetchAuditStatus = async () => {
			try {
				const response = await fetch('https://api01.r2logistics.com/api/v1/assets/audits/status', {
					method: 'GET',
					credentials: 'include', // Ensure cookies are sent
				});
				const data = await response.json();
				setAuditStatus(data.status);
			} catch (error) {
				console.error('Error fetching audit status:', error);
			}
		};

		fetchAuditStatus();
	}, []);

	// Fetch existing items from the API
	useEffect(() => {
		const fetchItems = async () => {
			try {
				const response = await fetch('https://api01.r2logistics.com/api/v1/assets/audits/items', {
					method: 'GET',
					credentials: 'include', // Ensure cookies are sent
				});
				const data = await response.json();
				setItems(data || []);
			} catch (error) {
				console.error('Error fetching items:', error);
			}
		};

		fetchItems();
	}, []);

	const handleAddItem = async () => {
		if (!assetId && !description) {
			alert('Must provide an Asset ID or Description.');
			return;
		}
		try {
			const response = await fetch('https://api01.r2logistics.com/api/v1/assets/audits/items', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include', // Send cookies for authentication
				body: JSON.stringify({ asset_id: assetId, description }),
			});

			if (!response.ok) {
				throw new Error('Error adding item');
			}

			const newItem = await response.json();
			setItems([...items, newItem]);
			setAssetId('');
			setDescription('');
		} catch (error) {
			console.error('Error adding item:', error);
		}
	};

	const handleRemoveItem = async (id) => {
		try {
			const response = await fetch(`https://api01.r2logistics.com/api/v1/assets/audits/items/${id}`, {
				method: 'DELETE',
				credentials: 'include', // Send cookies for authentication
			});

			if (!response.ok) {
				throw new Error('Error removing item');
			}

			setItems(items.filter(item => item.id !== id));
		} catch (error) {
			console.error('Error removing item:', error);
		}
	};

	const addItemOnEnter = (e) => {
		if (e.key === 'Enter') {
			handleAddItem();
		}
	};

	const AuditItem = ({ item, onRemove }) => {
		return (
			<StyledTableRow
				onMouseEnter={(e) => {
					const button = e.currentTarget.querySelector('button');
					if (button && auditStatus === 'Open') button.style.visibility = 'visible';
				}}
				onMouseLeave={(e) => {
					const button = e.currentTarget.querySelector('button');
					if (button) button.style.visibility = 'hidden';
				}}
			>
				<td style={{ textAlign: 'center', backgroundColor: isValidDamn(item.asset_id) ? 'initial' : 'red' }}>{item.asset_id}</td>
				<td style={{ textAlign: 'center' }}>{item.description}</td>
				<td style={{ textAlign: 'center', position: 'relative' }}>
					<button
						onClick={() => onRemove(item.id)}
						style={{
							visibility: 'hidden',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							cursor: 'pointer', // Change cursor to pointer when hovered
							background: 'none',
							border: 'none',
							color: 'red',
							fontSize: '16px',
						}}
					>
						❌
					</button>
				</td>
			</StyledTableRow>
		);
	};

	return (
		<CenteredContainer>
			<WrapperDiv>
			<FirstChild>
				<h1 style={{textAlign: 'center'}}>Asset Audit</h1>
				<p style={{textAlign: 'center'}}>Current Status: <span style={{ color: auditStatus === 'Open' ? 'green' : 'red', fontWeight: 'bold' }}>{auditStatus}</span></p>
				<table style={{ borderCollapse: 'collapse' }}>
					<thead>
						<tr>
							<th>Asset ID</th>
							<th>Description</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{items && items.map(item => (
							<AuditItem key={item.id} item={item} onRemove={handleRemoveItem} />
						))}
						<tr>
							<td>
								<input
									type="text"
									placeholder="Asset ID"
									value={assetId}
									onChange={(e) => setAssetId(e.target.value)}
									style={{ width: '10ch', borderColor: isValidDamn(assetId) ? 'initial' : 'red' }}
									disabled={auditStatus !== 'Open'}
									onKeyDown={addItemOnEnter}
								/>
							</td>
							<td>
								<input
									type="text"
									placeholder="Description"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									style={{ width: '50ch' }}
									disabled={auditStatus !== 'Open'}
									onKeyDown={addItemOnEnter}
								/>
							</td>
							<td>
								<button onClick={handleAddItem} disabled={(!isValidDamn(assetId) && requireValidDamn) || auditStatus !== 'Open'}>Add Item</button>
							</td>
						</tr>
					</tbody>
				</table>
				<div style={{ marginTop: '20px', textAlign: 'center' }}>
					<button
						onClick={async () => {
							if (window.confirm('Are you sure you want to submit your audit? No further modifications can be made after submission.')) {
								try {
									const response = await fetch('https://api01.r2logistics.com/api/v1/assets/audits/status', {
										method: 'PATCH',
										headers: {
											'Content-Type': 'application/json',
										},
										credentials: 'include', // Send cookies for authentication
										body: JSON.stringify({ status: 'Completed' }),
									});

									if (!response.ok) {
										throw new Error('Error completing audit');
									}

									setAuditStatus('Completed');
								} catch (error) {
									console.error('Error completing audit:', error);
								}
							}
						}}
						disabled={auditStatus !== 'Open'}
						style={{
							padding: '10px 20px',
							fontSize: '16px',
							backgroundColor: auditStatus !== 'Open' ? 'grey' : '#007BFF',
							color: 'white',
							border: 'none',
							borderRadius: '5px',
							cursor: auditStatus !== 'Open' ? 'not-allowed' : 'pointer',
							float: 'none',
							marginTop: '25px',
						}}
					>
						Submit Audit
					</button>
				</div>
			</FirstChild>
			<SecondChild>
				<h2 style={{textAlign: 'center'}}>Instructions</h2>
				<p>
					Enter the <Bold>Asset ID</Bold> and <Bold>Description</Bold> of each R2 asset in your possession, one at a time. 
					After each entry, click "<Bold>Add Item</Bold>" to save it to your audit. You can return later to complete your audit if needed.
				</p>
				<p>
					Asset numbers that are not valid will be highlighted in red and should be verified before submission.
				</p>
				<p>
					To remove an item from the audit, hover over the item and click the "❌" button.
				</p>
				<p>
					Once you have added all of your items, click "<Bold>Submit Audit</Bold>" to finalize the process. <Bold>Note:</Bold> After submission, you will no longer be able to make any changes.
				</p>
			</SecondChild>
			</WrapperDiv>
		</CenteredContainer>
	);
};


export default TechAudit;
